<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16" fill="#5B5B5B">
              <path d="M2.5 2C2.222656 2 2 2.222656 2 2.5L2 4.5C2 4.777344 2.222656 5 2.5 5L4.5 5C4.777344 5 5 4.777344 5 4.5L5 2.5C5 2.222656 4.777344 2 4.5 2 Z M 3 3L4 3L4 4L3 4 Z M 7 3L7 4L14 4L14 3 Z M 2.5 6C2.222656 6 2 6.222656 2 6.5L2 8.5C2 8.777344 2.222656 9 2.5 9L4.5 9C4.777344 9 5 8.777344 5 8.5L5 6.5C5 6.222656 4.777344 6 4.5 6 Z M 3 7L4 7L4 8L3 8 Z M 7 7L7 8L14 8L14 7 Z M 2.5 10C2.222656 10 2 10.222656 2 10.5L2 12.5C2 12.777344 2.222656 13 2.5 13L4.5 13C4.777344 13 5 12.777344 5 12.5L5 10.5C5 10.222656 4.777344 10 4.5 10 Z M 3 11L4 11L4 12L3 12 Z M 7 11L7 12L14 12L14 11Z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">List of Branch</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-between flex-wrap justify-xs-center">
          <SelectComponent
            :items="types"
            :placeholder="false"
            return-type="object"
            @getObject="getObject"
            class="w-64 m-1" />
          <SearchBox
            placeholder="Search"
            class="w-74 p-2 m-1"
            :results="searchResults"
            v-model="keyword"
            result-key="branchName"
            @on-result="onResult"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="branchList" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="branchId" md-label="BRANCH ID" class="text-victoria">{{ item.branchId }}</md-table-cell>
            <md-table-cell md-sort-by="branchName" md-label="BRANCH NAME">{{ item.branchName }}</md-table-cell>
            <md-table-cell md-sort-by="numberOfStudent" md-label="NO. OF STUDENT">{{ item.numberOfStudent }}</md-table-cell>
            <md-table-cell md-sort-by="numberOfAdmin" md-label="NO. OF ADMIN">{{ item.numberOfAdmin }}</md-table-cell>
            <md-table-cell md-sort-by="numberOfTrainer" md-label="NO. OF TRAINER">{{ item.numberOfTrainer }}</md-table-cell>
            <md-table-cell md-sort-by="numberOfCourse" md-label="NO. OF COURSE">{{ item.numberOfCourse }}</md-table-cell>
            <md-table-cell md-sort-by="createdAt" md-label="CREATED AT">{{ item.createdAt }}</md-table-cell>
            <span class="action">
              <md-icon class="bg-heather rounded-full p-1 text-white text-base mx-1" @click.native="onShowDetails(item)">visibility</md-icon>
              <md-icon class="bg-victoria rounded-full p-1 text-white text-base mx-1" @click.native="onShowEdit">edit</md-icon>
            </span>
          </template>
        </Table>

        <div @click="onShowCreate" style="position: absolute; bottom: 0; right: 0"
          class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
            @on-start="onStart"
            @on-end="onEnd"
          />
      </div>
    <Dialog>
      <component :content="content" :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox,
    SelectComponent
    } from "@/components";
import { BOLShowDetails, BOLShowCreate, PromptEdit } from "@/components/molecule";

import cms from "@/data/cms";
import { branchList } from "@/data/master/branch-management/branch-creation"
import { mapMutations } from "vuex";
import { paginate } from "@/mixins/paginate";
export default {
    mixins: [paginate],
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        PromptEdit,
        BOLShowDetails,
        BOLShowCreate,
        SelectComponent
  },
  data() {
      return {
          currentIndex: 0,
          component: 'BOLShowCreate',
          types: cms.branches,
          content: null,
          keyword: '',
          selectedKeyword: '',
          query: '?',
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          searchParams : new URLSearchParams(),
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          },
          buttonFilters : cms.buttonFilters
      }
  },
  computed: {
    branchList() {
      if(this.selectedValue && this.selectedValue !== 'all') {
          return branchList.filter(item => this.toLower(item.branchName) == this.selectedValue);
      }
      if(this.pagination) {
        let data = branchList;
        if(this.itemIndex) {
          data[this.itemIndex].status =  this.status;
        }
        return data.slice(this.paginate.start, this.paginate.end);
      }
      return branchList;
    },
    searchResults() {
        if(this.keyword !== '' && this.keyword !== this.selectedKeyword) {
          return branchList.filter(item => this.toLower(item.branchName).includes(this.toLower(this.keyword)))
        }
        return [];
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    getObject(item) {
      this.onPrepareQueryString('type', this.toLower(item.name));
      this.selectedValue = this.toLower(item.name);
    },
    onShowDetails(item) {
      this.content = item;
      this.component = 'BOLShowDetails';
      this.dialog(true);
    },
    onShowCreate() {
      this.component = 'BOLShowCreate';
      this.dialog(true);
    },
    onShowEdit() {
      this.component = 'PromptEdit';
      this.dialog(true);
    },
    onResult(value) {
      this.keyword = this.selectedKeyword = value;
      this.onPrepareQueryString('search', this.keyword);
    },
    onStart(value) {
      this.paginate.start -= value;
      this.paginate.end -= value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onEnd(value) {
      this.paginate.start += value;
      this.paginate.end += value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onPrepareQueryString(key, value) {
      if(this.searchParams.has(key)) {
          this.searchParams.delete(key);
      }
      this.searchParams.append(key, value);
      console.log(this.searchParams.toString());
    },
    onFilteringData(index) {
      this.currentIndex = index;
      this.onPrepareQueryString('filter', this.buttonFilters[index].label);
    },
    onChangeStatus(item) {
      this.itemIndex = item.id;
      this.status = this.toLower(item.status) == 'active' ? 'de-active': 'Active'
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
}
</script>